
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        










































































.vertical-landing {
  background: $c-white-dark;

  &__hero {
    z-index: 1;
    color: $white;
  }
}

::v-deep .hero {
  .hero__slides__item-inner {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include mq($until: 'l') {
      padding-top: 6rem;
      padding-bottom: 7rem;
    }
  }

  .hero__slides__item__content {
    @include mq($until: 'l') {
      padding: 0 2rem 2rem;
      padding-bottom: 7rem;
    }
  }

  .hero__slides__item__headline {
    @include mq($until: 'l') {
      h2 {
        width: 98%;
      }
    }
  }
}

::v-deep .freelancer-landing-pack__speedlines {
  @include mq($until: 'l') {
    display: none;
  }
}
