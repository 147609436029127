
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        


























































































































.freelancer-landing-pack {
  position: relative;
  z-index: 1;
  margin-top: -80px;

  &__pack {
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    column-gap: $spacing * 4;
    row-gap: $spacing * 2;
    background-color: $white;
    border: 1px solid $c-light-gray;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba($black, 0.05);
    align-items: center;
    justify-items: center;
    padding: $spacing * 3;

    @include mq($until: l) {
      grid-template-columns: 1fr;
      padding: $spacing * 3 1rem;
    }
  }

  &__inner {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &__speedlines {
    left: 0;
  }

  &__left {
    position: relative;
    width: 100%;
    text-align: center;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -$spacing * 2;
      width: 1px;
      height: 100%;
      background: #dbdbdb;

      @include mq($until: l) {
        display: none;
      }
    }
  }

  &__right {
    color: $c-purple;
    font-family: $ff-alt;

    @include mq($until: l) {
      text-align: center;
    }
  }

  &__label {
    background: $c-purple;
  }

  &__logo {
    margin-top: $spacing;
  }

  &__rgus {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    gap: 3rem;

    &__item {
      position: relative;
      display: flex;

      &:not(:last-child) {
        &::after {
          content: '+';
          position: absolute;
          font-weight: bold;
          font-size: 20px;
          right: calc(-1.5rem - 5px);
          top: calc(50% - 1em);
        }
      }

      &__icon {
        @include mq($until: l) {
          height: 40px;
        }
      }
    }
  }

  &__price {
    position: relative;
    display: inline-flex;
    color: $c-promo-card-headline;
    font-weight: bold;
    font-size: 3.5rem;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 3px;
      border-radius: 10px;
      background: currentcolor;
      transform: rotate(-40deg);
      transform-origin: center;
      opacity: 0.9;
    }

    &-discount {
      font-size: 15rem;
      font-weight: 800;
      line-height: 1;

      .price-htva {
        display: inline;
        font-size: 1.8rem;
      }

      span {
        margin-right: $spacing / 2;
        font-size: 0.8em;
      }

      @include mq($until: l) {
        font-size: 12rem;
      }
    }

    &-actions {
      font-weight: bold;

      ::v-deep p {
        margin: 0;
      }
    }
  }

  &__bottom {
    grid-column: 1/-1;
    text-align: center;
  }

  &__button {
    line-height: 1;
    background: $c-green-light;
  }
}
